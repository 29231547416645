.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: url('./images/scipowerimagetrack.png'); /* เปลี่ยน path_to_your_image.jpg เป็น path ของรูปภาพของคุณ */
  background-size: contain; /* ทำให้ภาพพื้นหลังครอบคลุมทั้งหน้าจอโดยไม่ให้ส่วนใดหายไป */
  background-repeat: no-repeat;
  background-position: center;
  background-color: black; /* เปลี่ยนสีพื้นหลังเป็นสีที่คุณต้องการ */
}

.start-button {
  padding: 20px 40px;
  font-size: 34px;
  font-weight: bold; /* ทำให้ตัวอักษรหนาขึ้น */
  cursor: pointer;
  border: none;
  background-color: rgba(0, 255, 234, 0.7);
  border-radius: 5px;
  animation: pulse 2s infinite, blink 1.5s infinite; /* เรียกใช้อนิเมชั่น */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  100% {
    opacity: 0.9;
  }
}